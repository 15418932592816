export const NETWORKS = {
  1: "Main Net",
  2: "Deprecated Morden test network",
  3: "Ropsten test network",
  4: "Rinkeby test network",
  42: "Kovan test network",
  4447: "Truffle Develop Network",
  5777: "Ganache Blockchain",
  43114: "Avalanche Network",
  43113: "Avalanche Fuji Testnet",
};
